//CircularStd
@font-face {
	font-family: "CircularStd";
	src: url("../fonts/CircularStd/CircularStd-Black.ttf");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "CircularStd";
	src: url("../fonts/CircularStd/CircularStd-BlackItalic.ttf");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: "CircularStd";
	src: url("../fonts/CircularStd/CircularStd-Bold.ttf");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "CircularStd";
	src: url("../fonts/CircularStd/CircularStd-BoldItalic.ttf");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "CircularStd";
	src: url("../fonts/CircularStd/CircularStd-Medium.ttf");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "CircularStd";
	src: url("../fonts/CircularStd/CircularStd-MediumItalic.ttf");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "CircularStd";
	src: url("../fonts/CircularStd/CircularStd-Book.ttf");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "CircularStd";
	src: url("../fonts/CircularStd/CircularStd-BookItalic.ttf");
	font-weight: 400;
	font-style: italic;
}

//SF UI

@font-face {
	font-family: "SFUIText";
	src: url("../fonts/SFUIText/SFUIText-Light.ttf");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "SFUIText";
	src: url("../fonts/SFUIText/SFUIText-LightItalic.ttf");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "SFUIText";
	src: url("../fonts/SFUIText/SFUIText-Regular.ttf");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "SFUIText";
	src: url("../fonts/SFUIText/SFUIText-RegularItalic.ttf");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "SFUIText";
	src: url("../fonts/SFUIText/SFUIText-Medium.ttf");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "SFUIText";
	src: url("../fonts/SFUIText/SFUIText-MediumItalic.ttf");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "SFUIText";
	src: url("../fonts/SFUIText/SFUIText-Semibold.ttf");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "SFUIText";
	src: url("../fonts/SFUIText/SFUIText-SemiboldItalic.ttf");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "SFUIText";
	src: url("../fonts/SFUIText/SFUIText-Bold.ttf");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "SFUIText";
	src: url("../fonts/SFUIText/SFUIText-BoldItalic.ttf");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: "SFUIText";
	src: url("../fonts/SFUIText/SFUIText-Heavy.ttf");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "SFUIText";
	src: url("../fonts/SFUIText/SFUIText-HeavyItalic.ttf");
	font-weight: 900;
	font-style: italic;
}
